<template>
  <div class="columns">
     <div class="column is-one-third">
        <card-component
          title="1. Run multiple tests"
          class="tile is-child"
          :displayHelp="$options.name"
        >
        <validation-observer v-slot="{ handleSubmit }">
          <template v-if="xType === 'c'">
            <b-field label="Type of tests">
              <b-radio
                v-model="type"
                native-value="c"
              >
                Count tests
              </b-radio>
            </b-field>
          </template>
          <template v-else>
            <b-field label="Type of tests">
              <b-radio
                v-model="type"
                native-value="p"
              >
                Parametric
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="type"
                native-value="n"
              >
                Non-parametric
              </b-radio>
            </b-field>
            <b-field>
              <b-radio
                v-model="type"
                native-value="a"
              >
                Automatic
              </b-radio>
            </b-field>
          </template>
          <b-field
                label="Correction"
              >
                <b-select
                  v-model="correction"
                  expanded
                >
                <!-- <option value="holm">holm</option> -->
                <!-- <option value="hochberg">hochberg</option> -->
                <!-- <option value="hommel">hommel</option> -->
                <option value="BH">BH</option>
                <option value="bonferroni">bonferroni</option>
                <!-- <option value="BY">BY</option> -->
                <!-- <option value="fdr">fdr</option> -->
                <option value="none">none</option>

                </b-select>
            </b-field>
            <validation-provider
                v-slot="{ errors, valid}"
                :rules= {required:true,min_value:0,max_value:1}
                name="threshold"
                >
                    <b-field
                    label="Threshold"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                    >
                        <b-input
                            v-model="threshold"
                            expanded
                        ></b-input>
                    </b-field>
            </validation-provider>
          <hr/>
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run </span>
            </b-button>
          </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component title="2. Explore results of multiple tests" ></card-component>
        <card-no-result v-if="!resultsLoaded">
        <strong>How to set options?<br></strong>
        <p>Set options to obtain results from your differential analysis:
        <ul class="card-help-list">
          <li><strong>type</strong> can either be “parametric” (1-way ANOVA) or
          “nonparametric” (Kruskal-Wallis test), for <strong>standard numeric
          datasets</strong>. If you don’t know which one to choose, set type to
          “auto” and Shapiro-Wilk tests are performed to asses the normality
          of your data. If more than 5% of adjusted p-values (Bonferroni
          correction) are below 5%, type is automatically set to
          “nonparametric”.<br>
          If your numeric dataset is a <strong>count dataset</strong>, count
          tests (<i>i.e.</i>, Binomial Negative GLM tests based on
          <strong>edgeR</strong> implementation) are performed. It is advised
          to perform TMM or TMMwsp normalization before (in “Edit/Normalize
          dataset”), without log-transforming your data. Log-transformed
          datasets are processed with standard tests as explained above;</li>
          <li><strong>correction</strong> is the method for multiple test
          adjustment: “BH” is for Benjamini-Hochberg that controls the FDR, and
          “Bonferroni” controls the FWER and is more stringent. You can also set
          that option to “none” (not advised);</li>
          <li><strong>risk</strong>: is the threshold value (FDR, FWER or raw
          p-values) chosen to declare a result as significant.</li>
        </ul></p>
        <p><strong>Warning!</strong> Currently implemented tests are not well
        suited to deal with metagenomics data. Tests for metagenomics datasets
        are still to come (wait for the next release!). Meanwhile, use
        log-transformed counts or log-transformed compositional data to perform
        your differential on these datasets.</p>
        </card-no-result>

        <div v-else>
          <card-component>
            <b-tabs @input="displayResult">
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item v-if="png" label="Heatmap" icon="bar-chart" value="heatmap" />
              <b-tab-item v-if="basicTables" label="Summary" icon="table" value="basic-table" />
            </b-tabs>
          </card-component>
          <error :type='"notifications"' />
          <card-component v-show="currentResult==='plots'">
          <plotly
              v-if="graphicals.length===1"
              :data="graphicals[0]"
            ></plotly>
            <plotly-list
              v-else-if="graphicals.length>1"
              :data="graphicals"
            ></plotly-list>
        </card-component>
        <card-component
            v-if="currentResult==='heatmap'"
          >
          <png v-if="png" :data=png />
        </card-component>
        <div v-if="currentResult==='basic-table'">
          <card-component
            v-for="(bTable,index) in basicTables"
            :key="index"
            :title="bTable.title"
          >
            <basic-table
              :data="bTable"
            />
          </card-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
export default {
  name: 'RTestsMultiple',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    dataset: {
      type: String,
      default: null
    },
    xNature: {
      type: String,
      default: null
    },
    xLogt: {
      type: String,
      default: null
    }
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: null,
      type: 'a',
      correction: 'BH',
      threshold: 0.05,
      currentResult: 'plots',
      diffVars: []
    }
  },
  watch: {
    dataset: function () {
      this.datasetName = this.dataset
      this.type = this.xType
    }
  },
  computed: {
    xType: function () {
      if (this.analysisObj !== null) {
        return this.analysisObj.meta.func_args.type
      } else if ((this.xNature === 'rna-count' || this.xNature === 'metagenomics-count') && this.xLogt === 'no') {
        return 'c'
      } else {
        return 'a'
      }
    },
    params: function () {
      return {
        'func_name': 'r_multiple_tests',
        'datasetName': this.datasetName,
        'type': this.type,
        'correction': this.correction,
        'threshold': parseFloat(this.threshold),
        'out_graph': true
      }
    }
  },
  methods: {
    afterRun: function (data) {
      this.displayResult('plots')
      // if (data.DiffVar.length > 0) {
      //   this.diffVars = data.DiffVar
      // } else {
      //   this.diffVars = []
      // }
      this.$emit('is-computed',
        { 'objectName': data.ObjectName,
          'posthoc': data.Posthoc,
          'posthocplot': data.Posthocplot,
          'diffVars': data.DiffVar,
          'allVariables': data.AllVariables,
          'hasSign': data.HasSign })
    },
    displayResult: function (value) {
      this.currentResult = value
    }
  },
  mounted () {
    this.datasetName = this.dataset
    this.type = this.xType
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
